.x20footer {
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  padding: 0 1.3rem;
  width: 100%;
}
.x20footer .left-copyright {
  display: flex;
  justify-content: center;
  align-items: center;
}
.x20footer .left-copyright p {
  letter-spacing: -1px;
}

.common_btn {
    margin-left: 0.2rem;
    padding: 0 10px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;
    border: none;
    background: #5284ff;
    color: #fff;
}

.disable_btn {
    margin-left: 0.2rem;
    padding: 0 10px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;
    border: none;
    color: #fff;
    background: rgb(128, 128, 128);
}

.w-full{
    width: 100%;
}

.body-content{
    width: 1000px;
}

hover-item {
    cursor: pointer;
    color:red;
}

.one-row{
    display: flex;
    justify-content: center;
    align-items: center;
}

.common_copy_btn {
    width: 100px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    background: #5284ff;
    color: white;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-size: 18px;
    color:black;
}

.footer_area{
    width: 100%;
}

.mainBody{
    background-color: white;
}

.common_user-avater{
    width: 6rem;
    height: 6rem;
    border-radius: 9999px
}

.x20_comm_btn {
    margin-left: 0.2rem;
    padding: 0 10px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;
    border: none;
    background: #A020F0;
    color: #fff;
}

@media screen and (max-width: 768px) {
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color:white;
        font-size: 16px;
    }

    .mainBody{
        background-color: #0b002a;
    }

    .footer_area{
        background-color: #0b002a;
        color: white;
    }
}

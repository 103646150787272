.farm {
  width: 100%;
  min-height: 50vh;
  padding-bottom: 0.2rem;
  position: relative;
  margin: 0 auto;
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
}
.pubTitel {
  width: 100%;
  display: flex;
  justify-content: right;
  padding-right: 2rem;
}
.home_more {
  display: flex;
  justify-content: center;
  margin: 50px 0;
  height: 50px;
}
.home_more .home_more_button {
  width: 220px;
  height: 50px;
  font-family: PingFang SC, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  border-radius: 10px;
  border: none;
  background-color: #6001ff;
}
.home_more .home_more_button:hover,
.home_more .home_more_button:active {
  background: #6819ec;
}
@media screen and (max-width: 750px) {
  .farm {
    margin-left: 0;
    padding-left: 0;
    margin-top: 0.6rem;
  }
}

.list_item {
  width: 100%;
  height: auto;
  color: black;
  background: white;
  border: 1px solid rgba(96, 1, 255, 0.4);
  border-radius: 10px;
}
.list_item:hover {
  border: 1px solid #6001ff;
}
.list_item .img {
  width: 100%;
  position: relative;
}
.list_item .img > img {
  width: 100%;
  border-radius: 10px 10px 0 0;
}
.list_item .img .increase {
  position: absolute;
  top: 20px;
  right: 0;
  background: #35fffe;
  padding: 0 10px;
  height: 20px;
  font-size: 14px;
  color: #000;
  line-height: 20px;
  border-radius: 10px 0 0 10px;
}
.list_item .info {
  padding: 16px;
}
.list_item .info .name {
  font-size: 16px;
  font-weight: 700;
}
.list_item .info .desc {
  font-size: 12px;
  line-height: 16px;
  padding: 10px 0;
}
.list_item .info .marketcap {
  margin-top: 20px;
}
.list_item .info .marketcap .price {
  font-size: 14px;
  word-wrap: break-word;
}
